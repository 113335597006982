<!--  -->
<template>
  <div class="smart-meeting-room">
    <van-image
      width="6.9rem"
      height="3.01rem"
      fit="cover"
      class="img"
      :src="meetingRoom"
    />
    <div class="list">
      <!-- <van-icon name="coupon-o" /> -->
      <template v-for="(item, i) in list">
        <div
          class="list-item"
          @click="jump(item.url)"
          :key="i"
          v-if="item.permission"
        >
          <div class="section-left">
            <van-icon :name="item.icon" />
            <span class="label-name">{{ item.name }}</span>
          </div>
          <div class="right-icon">
            <van-icon name="arrow" size="0.2rem" />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

    </div>
  </div>
</template>

<script>
import meetingRoom from "@/assets/images/smartMeetingRoom/meetingRoom.png";
import Cookies from "js-cookie";

export default {
  data() {
    return {
      meetingRoom,
      list: [
        {
          name: "办公用品管理",
          icon: "apps-o",
          url: "/suppliesList",
          permission: false,
        },
        {
          name: "申领记录",
          icon: "label-o",
          url: "/suppliesHistory",
          permission: false,
        },
        {
          name: "我的申领",
          icon: "manager-o",
          url: "/mySupplies",
          permission: true,
        },
      ],
    };
  },

  components: {},

  computed: {},

  mounted() {
    this.setPermission();
  },

  methods: {
    jump(url) {
      this.$router.push(url);
    },
    setPermission() {
      let permission = localStorage.getItem("permission_H5");
      if (permission) {
        // 办公用品管理
        let permissionArr = JSON.parse(permission);
        if (permissionArr.indexOf("officeSupplies") > -1) {
          this.$set(this.list[0],"permission", true);
        }
        // 申领记录
        if (permissionArr.indexOf("officeSupplies:collection") > -1) {
          this.$set(this.list[1],"permission", true);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.smart-meeting-room {
  width: 100%;
  height: auto;
  min-height: 100%;
  background: #fafafa;
  position: relative;
  padding: 0.3rem;
  .img {
    border-radius: 0.16rem;
  }
  .list {
    display: flex;
    flex-direction: column;
    width: 100%;
    .list-item {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 1rem;
      margin-top: 0.2rem;
      background: #fff;
      line-height: 1rem;
      padding: 0rem 0.2rem;
      border-radius: 0.2rem;
      .section-left {
        .label-name {
          font-weight: 400;
          color: #333333;
          font-size: 0.3rem;
          white-space: nowrap;
          line-height: 0.42rem;
          text-align: left;
          margin-left: 0.2rem;
        }
      }
      .right-icon {
      }
    }
  }
}
</style>
